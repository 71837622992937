import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import '../../../../static/styles/fontawesome-5.15.2/css/fontawesome.min.css'
import MotieTable from '../../../components/MotieTable'
import { useIntl } from '@intractive/gatsby-plugin-react-intl'

const PageMoties = ({ data }) => {
  const intl = useIntl(); 
  return (

    <Layout>
      <Helmet>
        <title>{intl.formatMessage({id: "Moties"})} · STIP</title>
      </Helmet>
      <div className="row main-content" style={{float: 'inherit'}}>
        <div className="large-10 medium-9 small-12 columns">
          <h1>{intl.formatMessage({id: "Moties"})}</h1>
          <p>
            {intl.formatMessage({id: "In de onderstaande tabel vind je een overzicht van alle ingediende voorstellen van de fractie vanaf maart 2018."})}
            {" "}
            {intl.formatMessage({id: 'Het is mogelijk om te sorteren en filters toe te passen. In de "Type" kolom kan je zien of het gaat om een motie (M), amendement (A), Toezegging (T), Initiatiefvoorstel (I) of Schriftelijke Vragen (SV).'})}
            {" "}
            {intl.formatMessage({id: "Heb je vragen over een voorstel? Stel hem aan het verantwoordelijke fractielid of mail naar"})}{" "}<a href="mailto:fractie@stipdelft.nl">fractie@stipdelft.nl</a></p>
        </div>
        <div className="large-2 medium-3 hide-for-small rondIcoon columns"> 
        </div>
        <div className="columns large-12 medium-12 small-12">
          <br/>
          <MotieTable tabeldata={data.moties.edges}></MotieTable>
        </div>
      </div>
    </Layout>
  )
}

export default PageMoties

export const query = graphql`
  query($node_locale: String) {
    moties: allContentfulMotieAmendementOfToezegging(
      sort: { fields: datumIngediend, order: DESC }
      filter: {node_locale: {eq: $node_locale}}
    )
      {
      edges {
        node {
          datumIngediend
          type
          motietitel
          indieners
          fractielid {
            name
            slug
          }
          portefeuilles {
            titel
            slug
          }
          beschrijving {
            beschrijving
          }
          resultaat
          risLink
        }
      }
    }
  }
`
